import { ThemeProvider } from 'styled-components'
import theme from 'eaternity-core/build/theme'
import useBowserState from 'eaternity-core/build/hooks/useBowserState'
import NotSupported from 'eaternity-core/build/modules/core/components/NotSupported'
import LoadingIndicatorModalContainer from 'eaternity-core/build/modules/modal/containers/LoadingIndicatorModalContainer'
import PropTypes from 'prop-types'
import React, {lazy, Suspense, useEffect} from 'react'
import ReactResizeDetector from 'react-resize-detector'
import {Route} from 'react-router-dom'
import RoutesContainer from '../../routing/containers/RoutesContainer'

const AddOrEditBusinessPartnerModalContainer = lazy(() =>
  import(
    'eaternity-core/build/modules/modal/containers/AddOrEditBusinessPartnerModalContainer'
  )
)

const AddOrEditOrganisationModalContainer = lazy(() =>
  import(
    'eaternity-core/build/modules/modal/containers/AddOrEditOrganisationModalContainer'
  )
)

const AddOrEditRestaurantModalContainer = lazy(() =>
  import(
    'eaternity-core/build/modules/modal/containers/AddOrEditRestaurantModalContainer'
  )
)

const AddOrEditUserModalContainer = lazy(() =>
  import(
    'eaternity-core/build/modules/modal/containers/AddOrEditUserModalContainer'
  )
)

const AuthTokenExpiredModalContainer = lazy(() =>
  import(
    'eaternity-core/build/modules/modal/containers/AuthTokenExpiredModalContainer'
  )
)

const ConfigureProductModalContainer = lazy(() =>
  import(
    'eaternity-core/build/modules/productEditView/containers/ConfigureProductModalContainer'
  )
)

const CompositeDeleteModalContainer = lazy(() =>
  import(
    'eaternity-core/build/modules/modal/containers/CompositeDeleteModalContainer'
  )
)

const LinkExpiredModalContainer = lazy(() =>
  import(
    'eaternity-core/build/modules/modal/containers/LinkExpiredModalContainer'
  )
)

const LoginAgainModalContainer = lazy(() =>
  import(
    'eaternity-core/build/modules/modal/containers/LoginAgainModalContainer'
  )
)

const LogoutWarningModalContainer = lazy(() =>
  import(
    'eaternity-core/build/modules/modal/containers/LogoutWarningModalContainer'
  )
)

const BuyLicenseModalContainer = lazy(() =>
  import(
    'eaternity-core/build/modules/modal/containers/BuyLicenseModalContainer'
  )
)

const DayPickerToolTipContainer = lazy(() =>
  import(
    'eaternity-core/build/modules/toolTip/containers/DayPickerToolTipContainer'
  )
)

const ExportsModalContainer = lazy(() =>
  import('eaternity-core/build/modules/modal/containers/ExportsModalContainer')
)

const ExportInfoModalContainer = lazy(() =>
  import(
    'eaternity-core/build/modules/modal/containers/ExportInfoModalContainer'
  )
)

const OfflineModal = lazy(() =>
  import('eaternity-core/build/modules/modal/components/OfflineModal')
)

const ProductIconSprite = lazy(() =>
  import('eaternity-core/build/modules/icons/components/ProductIconSprite')
)

const SelectPreviewCollectionModalContainer = lazy(() =>
  import(
    'eaternity-core/build/modules/modal/containers/SelectPreviewCollectionModalContainer'
  )
)

const ReloadModalContainer = lazy(() =>
  import('eaternity-core/build/modules/modal/containers/ReloadModalContainer')
)

const RemoveBusinessModalContainer = lazy(() =>
  import(
    'eaternity-core/build/modules/modal/containers/RemoveBusinessModalContainer'
  )
)

const RemoveRestaurantModalContainer = lazy(() =>
  import(
    'eaternity-core/build/modules/modal/containers/RemoveRestaurantModalContainer'
  )
)

const RemoveUserModalContainer = lazy(() =>
  import(
    'eaternity-core/build/modules/modal/containers/RemoveUserModalContainer'
  )
)

const ResizeModalContainer = lazy(() =>
  import('eaternity-core/build/modules/modal/containers/ResizeModalContainer')
)

const SearchModalContainer = lazy(() =>
  import('eaternity-core/build/modules/search/containers/SearchModalContainer')
)

const BillingDetailsModalContainer = lazy(() =>
  import(
    'eaternity-core/build/modules/modal/containers/BillingDetailsModalContainer'
  )
)

const RestaurantSettingsModalContainer = lazy(() =>
  import(
    'eaternity-core/build/modules/modal/containers/RestaurantSettingsModalContainer'
  )
)

const SettingsModalContainer = lazy(() =>
  import('eaternity-core/build/modules/modal/containers/SettingsModalContainer')
)

const UserSettingsModalContainer = lazy(() =>
  import(
    'eaternity-core/build/modules/modal/containers/UserSettingsModalContainer'
  )
)

const App = ({
  checkServerStatus,
  daysUntilLicenseExpiry,
  setResizing,
  showBuyLicenseModal,
  startEnrichStream,
  startShowBuyLicenseModalInterval,
  stopShowBuyLicenseModalInterval,
  startSyncStream,
  stopEnrichStream,
  stopSyncStream,
  subscribeToAdminSocket,
  unsubscribeFromAdminSocket
}) => {
  const {isValidBrowser} = useBowserState()

  useEffect(() => {
    if (isValidBrowser) {
      // the following setTimeout makes the user experience much nicer when the
      // user clicks on a login or register link and thus enters the app via the
      // verify route. the verify route redirects to "/" after 4s...
      let timeoutId
      if (daysUntilLicenseExpiry <= 31) {
        timeoutId = window.setTimeout(showBuyLicenseModal, 10 * 1000)
      }
      startShowBuyLicenseModalInterval()

      if (!window.Cypress) {
        startEnrichStream()
        startSyncStream()
        subscribeToAdminSocket()
      }

      checkServerStatus()

      return () => {
        stopShowBuyLicenseModalInterval()

        if (!window.Cypress) {
          stopEnrichStream()
          stopSyncStream()
          unsubscribeFromAdminSocket()
        }

        if (daysUntilLicenseExpiry <= 31) {
          window.clearTimeout(timeoutId)
        }
      }
    }
  }, [
    checkServerStatus,
    daysUntilLicenseExpiry,
    isValidBrowser,
    showBuyLicenseModal,
    startEnrichStream,
    startShowBuyLicenseModalInterval,
    startSyncStream,
    stopEnrichStream,
    stopShowBuyLicenseModalInterval,
    stopSyncStream,
    subscribeToAdminSocket,
    unsubscribeFromAdminSocket
  ])

  return !isValidBrowser ? (
    <Route render={() => <NotSupported />} />
  ) : (
    <ThemeProvider theme={theme}>
      <ReactResizeDetector handleWidth onResize={() => setResizing(true)} />
      <RoutesContainer />
      <Suspense fallback={<LoadingIndicatorModalContainer isOpen />}>
        <ProductIconSprite />
        <AddOrEditBusinessPartnerModalContainer />
        <AddOrEditOrganisationModalContainer />
        <AddOrEditRestaurantModalContainer />
        <AddOrEditUserModalContainer />
        <AuthTokenExpiredModalContainer />
        <CompositeDeleteModalContainer />
        <ConfigureProductModalContainer />
        <DayPickerToolTipContainer />
        <ExportsModalContainer />
        <ExportInfoModalContainer />
        <LinkExpiredModalContainer />
        <LoginAgainModalContainer />
        <LogoutWarningModalContainer />
        <BuyLicenseModalContainer />
        <ReloadModalContainer />
        <RemoveBusinessModalContainer />
        <RemoveRestaurantModalContainer />
        <RemoveUserModalContainer />
        <ResizeModalContainer />
        <SearchModalContainer />
        <SelectPreviewCollectionModalContainer />
        <BillingDetailsModalContainer />
        <RestaurantSettingsModalContainer />
        <SettingsModalContainer />
        <UserSettingsModalContainer />
        <OfflineModal />
      </Suspense>
    </ThemeProvider>
  )
}

App.propTypes = {
  checkServerStatus: PropTypes.func.isRequired,
  daysUntilLicenseExpiry: PropTypes.number,
  setResizing: PropTypes.func.isRequired,
  showBuyLicenseModal: PropTypes.func.isRequired,
  startEnrichStream: PropTypes.func.isRequired,
  startShowBuyLicenseModalInterval: PropTypes.func.isRequired,
  startSyncStream: PropTypes.func.isRequired,
  stopEnrichStream: PropTypes.func.isRequired,
  stopShowBuyLicenseModalInterval: PropTypes.func.isRequired,
  stopSyncStream: PropTypes.func.isRequired,
  subscribeToAdminSocket: PropTypes.func.isRequired,
  unsubscribeFromAdminSocket: PropTypes.func.isRequired
}

export default App
